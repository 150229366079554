<template>
  <div class="jumbotron">
    <b-container class="mt-4">
      <h1 class="my-4" v-if="page">{{ page.title }}</h1>
      <template v-if="productList">
        <div class="news-list">
          <ul class="d-flex flex-wrap mx-0">
            <li class="col-12 col-md-6 p-3" v-for="product_item in productList.list" :key="product_item.id">
              <!--<b-link rel="" :to="news_item.url" :target="news_item.newWindow?'_blank':''">-->
              <b-link
                :href="product_item.url ? product_item.url : ''"
                :to="product_item.url ? '' : { name: 'product-detail', params: { id: product_item.id } }"
                :target="product_item.newWindow ? '_blank' : ''"
              >
                <div class="d-flex flex-wrap pb-3 content_title align-items-center">
                  <div class="col-12 col-md-2 p-0">
                    <!-- <b-img :src="`${require('@/images/s_banner1.png')}`" /> -->
                    <b-img
                      :src="product_item.cover ? product_item.cover : `${require('@/assets/images/s_banner0.png')}`"
                      :atl="product_item.title"
                    />
                  </div>
                  <div class="col-12 col-md-10 my-auto item_text">
                    <p class="blue">{{ product_item.inputAt }}</p>
                    <p class="sub-title">{{ product_item.title }}</p>
                  </div>
                </div>
              </b-link>
            </li>
          </ul>
        </div>
        <div class="text-center my-5" v-if="productList.total / curNum > curpage">
          <a rel="" class="btn btn-round news_more" @click="handleMore">{{ $t('查看更多') }} + </a>
        </div>
      </template>
    </b-container>
  </div>
</template>

<script>
import { lanToISO639, ISO639ToLan } from '@/utils'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'

export default {
  components: {},
  data() {
    return {
      chartData: [],
      curpage: 1,
      productList: {},
      curNum: 10
    }
  },
  computed: {
    ...mapState('page', ['product']),
    ...mapState('common', ['page']),
    alreadyInited: function() {
      return !!this.menu
    },
    lang() {
      return lanToISO639(this.$getLocale())
    }
  },
  watch: {},
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('page/FETCH_PRODUCT', {
            cookies,
            slug: 'product',
            limit: 10
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(err => {
            console.log('object', err)
            reject()
          })
      })
    },
    hanldeInit() {
      this.productList = this.product
      if (this.page) this.title = this.page.title
    },
    handleMore() {
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      return new Promise((resolve, reject) => {
        this.curpage += 1
        Promise.all([
          this.$store.dispatch('page/FETCH_PRODUCT', {
            cookies,
            slug: 'product',
            page: this.curpage,
            limit: 10
          })
        ])
          .then(() => {
            this.productList.list = this.productList.list.concat(this.product.list)
            resolve()
          })
          .catch(err => {
            reject()
          })
      })
    }
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  beforeCreate() {},
  created() {},
  serverPrefetch() {
    return this.fetchData()
  },
  mounted() {
    this.fetchData()
  },
  head() {
    return {
      title: '市場資訊 - 大豐銀行',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  }
}
</script>
